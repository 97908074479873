import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import SocialShare from "./../../components/share/socialshare";
import { connect } from "react-redux";
import RadyodPlayer from "../player/RadyodPlayer";
import { setPlayingState, setRadioData } from "../../redux/radio/action";
import { fetchRadioValue } from '../../common/helper.js'
import { Helmet } from 'react-helmet';

const YoutubeD = require('../../img/youtube-d.svg?v=2');
const SpotifyD = require('../../img/spotify-d.svg?v=2');
const InstagramD = require('../../img/instagram-d.svg?v=2');
const FacebookD = require('../../img/facebook-d.svg?v=2');
const TwitterD = require('../../img/twitter-d.svg?v=2');

class RadioPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      active: false,
      popupVisible: false,
      widgetClass: window.innerWidth < 991 ? 'small' : '',
      playing: false
    };
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      if (window.innerWidth < 991) {
        if (this.state.active)
          this.setState({ widgetClass: 'active' })
        else
          this.setState({ widgetClass: ' small' })
      }
      else
        this.setState({widgetClass: ''})
    });

    fetchRadioValue(this.props.config.LiveRadioInfoUrl, (radioData) => setRadioData(radioData))
    setInterval(() => { fetchRadioValue(this.props.config.LiveRadioInfoUrl, (radioData) => setRadioData(radioData)) }, 15000);
  }

  componentDidUpdate() {
    const body = document.querySelector("body");
    if (this.state.active) {
      body.style.overflow = "hidden";
    } else {
      body.removeAttribute("style");
    }
  }

  onClickPlay = (playing) => {
    if (playing)
      setPlayingState({
        page: 'layout',
        item: this.props.config.StreamUrl,
        index: 0
      })
    this.setState({ playing })
  }

    pushVideoToBdmp = (item, e) => {
        window.bdmp('video', {
            'videoIdentifier': item.programName + '/' + item.songName,
            'videoLabel': item.Description,
            'videoPosterUrl': item.programImage,
            'videoPublishedDate': null,
            'videoDuration': e.duration,
            'videoLiveStream': e.isLive
        });
        if (e.playing) {
            window.bdmp('videoTrack', {
                'videoIdentifier': item.programName + '/' + item.songName,
                'videoCurrentTime': e.progress.playedSeconds
            });
        }
    }

  render() {
    const { radioData } = this.props;
    return (
      <>
      <Helmet>
        <meta property="creators" content="Radyo D" />
      </Helmet>
        <SocialShare
          visible={this.state.popupVisible}
          title={"Şu anda " + radioData.songName + " dinliyorum."}
          url={this.props.config.ProductionUrl}
          visibleEvent={visible => this.setState({ popupVisible: visible })}
        />
        <div className={'col-12 radio-widget ' + (this.state.active ? this.state.widgetClass.replace('small', 'active') : this.state.widgetClass)}
        >
          <span
            className="radioBg"
            style={{
              backgroundImage: "url('" + radioData.programImage + "')"
            }}
          ></span>
          <button
            className="drop-radio open"
            onClick={() => this.setState({ active: !this.state.active })}
          >
            <i className="fa fa-chevron-right"></i>
          </button>
          {
            (window.innerWidth < 991 && !this.state.active) &&
            <div
              onClick={() => this.onClickPlay(!this.state.playing)}
              className="play-button"
            >
              <i className={this.state.playing ? 'fa fa-pause' : 'fa fa-play'}></i>
            </div>
          }
          <div className="broadcast-info">
            <div className="title">
              Şu An Yayında
              <span onClick={() => this.setState({ popupVisible: true })}>
                <i className="radio-share-icon far fa-share-square"></i> Paylaş
              </span>
            </div>
            <div className="desc">{radioData.programName}</div>
            <Link to="/" className="link">
              Stüdyo'yu Canlı İzle <i className="fa fa-chevron-right"></i>
            </Link>
          </div>
          <div className="duration-status">
            <p className="duration-status-text">
              Canlı Yayın Durduruldu!
              <span>Şu anda bir Podcast dinliyorsunuz...</span>
            </p>
            <button
              type="button"
              className="duration-status-button ui-button primary"
            >
              <i className="fa fa-play"></i> DEVAM ET
            </button>
          </div>
          <div className="broadcast-player">
            <div className="player">
              <div className="player-artist-img">
                <img src={radioData.songImage} alt="Radyo D" />
              </div>
              <div className="player-artist-detail">
                <div className="subtext">
                  <span className="name">{radioData.programName}</span>
                  <span className="song-name">{radioData.songName}</span>
                </div>
                <div className="player-content">
                  <div id="radyodPlayer"></div>
                  <RadyodPlayer
                    onClickPlay={(e) => {
                        this.onClickPlay(true);
                        this.pushVideoToBdmp(radioData, e)
                    }}
                    onPause={() => this.setState({ playing: false })}
                    onEnded={() => this.setState({ playing: false })}
                    playing={this.props.playingState.page === 'layout' && this.state.playing}
                    size={'lg'}
                    url={this.props.config.StreamUrl}
                    onProgress={(e) => { this.pushVideoToBdmp(radioData, e) }}
                  />
                </div>
              </div>
            </div>
            <div className="songs-list">
              {radioData.PrevSongName && <div className="song-info">
                <div className="song">
                  <i className="fa fa-backward" />
                  <div className="song-text">Önceki Şarkı</div>
                </div>
                <span>{radioData.PrevArtistName} - {radioData.PrevSongName}</span>
              </div>}
              {radioData.NextSongName && <div className="song-info">
                <div className="song">
                  <i className="fa fa-forward" />
                  <div className="song-text">Sıradaki Şarkı</div>
                </div>
                <span>{radioData.NextArtistName} - {radioData.NextSongName}</span>
              </div>}
            </div>
            <div className="social-media-wrapper">
              <div className="social-media-dividing-canopy">
              </div>
              <div className="social-media-interactive-portfolio">
                <div>
                  <a href='https://www.instagram.com/radyod104/' target="_blank"><img src={InstagramD} alt="Instagram" /></a>
                  <a href='https://www.facebook.com/radyod104' target="_blank"><img src={FacebookD} alt="Facebook" /></a>
                  <a href='https://twitter.com/radyod104' target="_blank"><img src={TwitterD} alt="Twitter" /></a>
                  <a href='https://www.youtube.com/@radyod104' target="_blank"><img src={YoutubeD} alt="Youtube" /></a>
                  <a href='https://open.spotify.com/show/6RUe2ahKiAVeeTW0VAtYWx' target="_blank"><img src={SpotifyD} alt="Spotify" /></a>
                </div>
              </div>
            </div>
          </div>
          <div className="all-podcast">
            <span
              style={{
                backgroundImage: 'url(\'' + radioData.nextProgramImage + '\')',
              }}
            />
            <div className="all-podcast-wrap">
              <div className="figure">
                <em>Sıradaki Program</em>
                <h3>{radioData.nextProgramName}</h3>
              </div>
              <Link to="/yayin-akisi" className="ui-button outline">
                <span>TÜM YAYIN AKIŞI</span>
                <i className="fa fa-chevron-right" />
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}


const mapStateToProps = (state) => ({ ...state.radioReducer })
const radio = withRouter(props => <RadioPlayer {...props} />)
const radioPlayer = connect(mapStateToProps)(radio);

export default radioPlayer;
